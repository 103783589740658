import React from "react"

const Brand = ({ type = "complete", ...props }) => {
  switch (type) {
    case "company":
      return <span className={props.className}>Boehringer Ingelheim</span>
    case "brand":
    default:
      return <span className={props.className}>HOPE</span>
  }
}

const ProgramName = ({ ...props }) => {
  return (
    <span className={props.className}>
      <Brand type="brand" /> Program
    </span>
  )
}

export { Brand, ProgramName }
