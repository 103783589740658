import { useStaticQuery, graphql } from "gatsby"

const useHomeImages = () => {
  const data = useStaticQuery(graphql`
    {
      saveTime: file(relativePath: { eq: "landing_page/back-in-time.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      saveWorries: file(relativePath: { eq: "landing_page/pain.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      saveMoney: file(relativePath: { eq: "landing_page/save-money.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      stepOne: file(relativePath: { eq: "landing_page/process_step1.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      stepTwo: file(relativePath: { eq: "landing_page/process_step2.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      stepThree: file(relativePath: { eq: "landing_page/process_step3.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useHomeImages
